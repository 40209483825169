@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Abyssinica+SIL&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.footer div h1{
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size:27px;
    margin-bottom:8px;
}
.footer div p{
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-size:18px;
    font-weight: 400;
    margin-bottom:5px;
}
.home{
    background-image: url("../public/hero-bg.png") !important;
    background-size: cover; 
}


.roboto-serif-header {
    font-family: "Roboto Serif", serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-size:40px;
    font-variation-settings:
      "wdth" 100,
      "GRAD" 0;
  }
.destination h1{
    font-family: "Roboto Serif", serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}
.home-header{
    font-family: "Roboto Serif", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 78px;
}
@media only screen and (max-width: 600px) {
    .home-header{
        font-family: "Roboto Serif", serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-size: 38px;
    }
  }
.review-container::-webkit-scrollbar,.package-container::-webkit-scrollbar,.destination-container::-webkit-scrollbar,.activity-container::-webkit-scrollbar,.jungle-images-container::-webkit-scrollbar{
    display: none;

}

@keyframes slide-in {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
.animate-slide-in {
  animation: slide-in 0.3s ease-out;
}